import gql from 'graphql-tag';

export const PRODUCT_REVIEW_CONTENT_FRAGMENT = gql`
  fragment contentFields on Content {
    id
    type
    width
    height
    uri
    thumbnail(fetchFormat: AUTO, crop: FILL, width: 100)
  }
`;

export const PRODUCT_REVIEW_COMMENT_CONNECTION_FRAGMENT = gql`
  fragment commentConnection on ProductReviewCommentConnection {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      cursor
      node {
        id
        content
        status
        writer {
          id
          nickname
        }
        host {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_PRODUCT_REVIEWS_QUERY = gql`
  ${PRODUCT_REVIEW_CONTENT_FRAGMENT}
  ${PRODUCT_REVIEW_COMMENT_CONNECTION_FRAGMENT}
  query GetProductReviews(
    $page: Int
    $size: Int
    $filter: ProductReviewFilter
  ) {
    productReview {
      reviewsForHostAndAdmin(page: $page, size: $size, filter: $filter) {
        totalCount
        edges {
          node {
            id
            content
            rating
            status
            writer {
              id
              nickname
            }
            createdAt
            updatedAt
            reported
            target {
              id
              product {
                id
                title
              }
              orderItem {
                info {
                  name
                  product {
                    id
                    title
                  }
                  host {
                    id
                    name
                  }
                  options {
                    id
                    level
                    name
                  }
                }
                schedule {
                  term {
                    duration
                    startedAt
                  }
                }
              }
            }
            thumbsUpCount
            commentCount
            reportCount
            attachmentCount
            attachmentContents {
              ...contentFields
            }
            latestComments {
              ...commentConnection
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_REVIEW_COMMENTS_QUERY = gql`
  ${PRODUCT_REVIEW_COMMENT_CONNECTION_FRAGMENT}
  query GetProductReviewComments(
    $productReviewId: ID!
    $first: Int
    $after: String
  ) {
    productReview {
      comments(
        productReviewId: $productReviewId
        first: $first
        after: $after
      ) {
        ...commentConnection
      }
    }
  }
`;

export const GET_PRODUCT_REVIEW_REPORTS_QUERY = gql`
  query GetProductReviewReports(
    $page: Int
    $size: Int
    $filter: ProductReviewReportingFilter
  ) {
    productReview {
      reports(page: $page, size: $size, filter: $filter) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            reporter {
              id
              nickname
            }
            reason
            status
            createdAt
            updatedAt
            type {
              id
              name
              description
            }
            productReview {
              id
              content
              rating
              status
              reportCount
              reported
              createdAt
              updatedAt
              target {
                id
                product {
                  id
                  title
                }
                orderItem {
                  info {
                    name
                    product {
                      id
                      title
                    }
                    host {
                      id
                      name
                    }
                    options {
                      id
                      level
                      name
                    }
                  }
                  schedule {
                    term {
                      duration
                      startedAt
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PRODUCT_REVIEW_REPORT_TYPES_QUERY = gql`
  query GetProductReviewReportTypes($filter: ProductReviewReportingTypeFilter) {
    productReview {
      reportTypes(filter: $filter) {
        id
        name
        description
      }
    }
  }
`;
