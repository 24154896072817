




















































































import Vue from 'vue';
import { ReviewState } from '../model/productReview';
import {
  ProductReviewCommentAPIResponse,
  ProductReviewCommentConnection,
  ProductReviewCommentModifyingParam,
  ProductReviewCommentWritingParam,
} from '../model/productReviewComment';

export default Vue.extend({
  name: 'ProductReviewCommentCard',
  components: {},
  props: {
    isHostAdmin: {
      type: Boolean,
    },
    latestComments: {
      type: Object,
    },
    productReviewId: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      originContent: '',
      productReviewComment: {} as ProductReviewCommentAPIResponse | undefined,
      ReviewState: ReviewState,
    };
  },
  watch: {
    latestComments(): void {
      this.productReviewComment = (
        this.latestComments as ProductReviewCommentConnection
      ).edges?.[0]?.node || { content: '' };
      this.originContent = this.productReviewComment.content;
    },
  },
  created() {
    this.productReviewComment = (
      this.latestComments as ProductReviewCommentConnection
    ).edges?.[0]?.node || { content: '' };
    this.originContent = this.productReviewComment.content;
  },
  methods: {
    convertReviewStatusColor(status: ReviewState): string {
      if (status === ReviewState.ACTIVE) {
        return 'primary';
      } else if (
        status === ReviewState.INACTIVE ||
        status === ReviewState.TEMP_BLOCKED
      ) {
        return 'success';
      } else {
        return 'danger';
      }
    },
    convertReviewStatusText(status: ReviewState): string {
      switch (status) {
        case ReviewState.ACTIVE:
          return '활성';
        case ReviewState.INACTIVE:
          return '비활성';
        case ReviewState.DELETED:
          return '삭제됨';
        case ReviewState.TEMP_BLOCKED:
          return '신고누적으로 인한 임시차단';
        case ReviewState.BLOCKED:
          return '차단';
      }
    },
    openCommentDeleteModal(): void {
      this.$emit('openCommentDeleteModal', this.productReviewComment);
    },
    saveProductReviewComment(): void {
      if (this.productReviewComment?.id) {
        const param: ProductReviewCommentModifyingParam = {
          id: parseInt(this.productReviewComment.id),
          content: this.productReviewComment.content,
        };
        this.$emit('modifyProductReviewComment', param);
      } else {
        const param: ProductReviewCommentWritingParam = {
          productReviewId: parseInt(this.productReviewId),
          content: this.productReviewComment?.content || '',
        };
        this.$emit('writeProductReviewComment', param);
      }
    },
  },
});
