import { ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import { ReviewState } from '../model/productReview';
import {
  ProductReviewCommentConnection,
  ProductReviewCommentModifyingParam,
  ProductReviewCommentsRequestParam,
  ProductReviewCommentWritingParam,
} from '../model/productReviewComment';
import {
  ProductReviewReportConnection,
  ProductReviewReportingParam,
  ProductReviewReportsRequestParam,
} from '../model/productReviewReport';
import {
  BLOCK_PRODUCT_REVIEW_MUTATION,
  DELETE_COMMENT_MUTATION,
  DELETE_REVIEW_MUTATION,
  MODIFY_COMMENT_MUTATION,
  REPORT_PRODUCT_REVIEW_MUTATION,
  RESTORE_PRODUCT_REVIEW_MUTATION,
  WRITE_COMMENT_MUTATION,
} from '../queries/mutation';
import {
  GET_PRODUCT_REVIEW_COMMENTS_QUERY,
  GET_PRODUCT_REVIEW_REPORTS_QUERY,
} from '../queries/query';

export class ProductReviewService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;
  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  public async deleteProductReview(id: string): Promise<boolean> {
    const deleteProductReviewResult = await this.apollo.mutate({
      mutation: DELETE_REVIEW_MUTATION,
      variables: {
        id,
      },
    });

    if (deleteProductReviewResult.data.errors?.length > 0) {
      throw deleteProductReviewResult.data.errors[0];
    }

    return deleteProductReviewResult.data.deleteProductReview
      .success as boolean;
  }

  public async findProductReviewComments(
    param: ProductReviewCommentsRequestParam
  ): Promise<ProductReviewCommentConnection> {
    try {
      const result = await this.apollo.query({
        query: GET_PRODUCT_REVIEW_COMMENTS_QUERY,
        variables: param,
      });

      return (result.data?.productReview?.comments || {
        edges: [],
        totalCount: 0,
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          endCursor: null,
        },
      }) as ProductReviewCommentConnection;
    } catch (error) {
      console.error(`findProductReviewComments error ${error}`);
      throw error;
    }
  }

  public async findProductReviewReports(
    param: ProductReviewReportsRequestParam
  ): Promise<ProductReviewReportConnection> {
    try {
      const result = await this.apollo.query({
        query: GET_PRODUCT_REVIEW_REPORTS_QUERY,
        variables: param,
      });

      return (result.data?.productReview?.reports || {
        edges: [],
        totalCount: 0,
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          endCursor: null,
        },
      }) as ProductReviewReportConnection;
    } catch (error) {
      console.error(`findProductReviewReports error ${error}`);
      throw error;
    }
  }

  public async blockProductReview(id: string): Promise<ReviewState> {
    const blockProductReviewResult = await this.apollo.mutate({
      mutation: BLOCK_PRODUCT_REVIEW_MUTATION,
      variables: {
        id,
      },
    });

    if (blockProductReviewResult.data.errors?.length > 0) {
      throw blockProductReviewResult.data.errors[0];
    }

    return blockProductReviewResult.data.blockProductReview
      .status as ReviewState;
  }

  public async restoreProductReview(id: string): Promise<ReviewState> {
    const restoreProductReviewResult = await this.apollo.mutate({
      mutation: RESTORE_PRODUCT_REVIEW_MUTATION,
      variables: {
        id,
      },
    });

    if (restoreProductReviewResult.data.errors?.length > 0) {
      throw restoreProductReviewResult.data.errors[0];
    }

    return restoreProductReviewResult.data.restoreProductReview
      .status as ReviewState;
  }

  public async rollbackTempBlockedProductReview(
    id: string
  ): Promise<ReviewState> {
    const rollbackProductReviewResult = await this.apollo.mutate({
      mutation: RESTORE_PRODUCT_REVIEW_MUTATION,
      variables: {
        id,
      },
    });

    if (rollbackProductReviewResult.data.errors?.length > 0) {
      throw rollbackProductReviewResult.data.errors[0];
    }

    return rollbackProductReviewResult.data.rollbackTempBlockedProductReview
      .status as ReviewState;
  }

  public async deleteProductReviewComment(id: string): Promise<boolean> {
    const deleteCommentResult = await this.apollo.mutate({
      mutation: DELETE_COMMENT_MUTATION,
      variables: {
        id,
      },
    });

    if (deleteCommentResult.data.errors?.length > 0) {
      throw deleteCommentResult.data.errors[0];
    }

    return deleteCommentResult.data.deleteProductReviewComment
      .success as boolean;
  }

  public async writeProductReviewComment(
    writeCommentParam: ProductReviewCommentWritingParam
  ): Promise<string> {
    const writeCommentResult = await this.apollo.mutate({
      mutation: WRITE_COMMENT_MUTATION,
      variables: {
        param: writeCommentParam,
      },
    });

    if (writeCommentResult.data.errors?.length > 0) {
      throw writeCommentResult.data.errors[0];
    }

    return writeCommentResult.data.writeProductReviewComment.id as string;
  }

  public async modifyProductReviewComment(
    modifyCommentParam: ProductReviewCommentModifyingParam
  ): Promise<string> {
    const modifyCommentResult = await this.apollo.mutate({
      mutation: MODIFY_COMMENT_MUTATION,
      variables: {
        param: modifyCommentParam,
      },
    });

    if (modifyCommentResult.data.errors?.length > 0) {
      throw modifyCommentResult.data.errors[0];
    }

    return modifyCommentResult.data.modifyProductReviewComment.id as string;
  }

  public async reportProductReview(
    reportReviewParam: ProductReviewReportingParam
  ): Promise<string> {
    const reportReviewResult = await this.apollo.mutate({
      mutation: REPORT_PRODUCT_REVIEW_MUTATION,
      variables: {
        param: reportReviewParam,
      },
    });

    if (reportReviewResult.data.errors?.length > 0) {
      throw reportReviewResult.data.errors[0];
    }

    return reportReviewResult.data.reportProductReview.id as string;
  }
}
