



















































































import Vue from 'vue';
import Icon from '@/components/Icon/Icon.vue';
import { ReviewState } from '../model/productReview';
import { getDomainName } from '@/env';

export default Vue.extend({
  name: 'ProductReviewCard',
  components: {
    Icon,
  },
  props: ['isHostAdmin', 'productReview', 'disabled'],
  data() {
    return {
      ReviewState: ReviewState,
    };
  },
  computed: {
    fripUrl(): string {
      return getDomainName();
    },
    productId() {
      return this.productReview.target.product?.id
        ? this.productReview.target.product?.id
        : '';
    },
    productTitle() {
      return this.productReview.target?.product?.title
        ? this.productReview.target.product.title
        : '상품 정보 없음';
    },
    productOption() {
      return this.productReview.target
        ? this.productReview.target.orderItem?.info?.options
            ?.map((option: { name: string }) => option.name)
            .join(' | ')
        : '옵션 정보 없음';
    },
  },
  methods: {
    convertReviewStatusColor(status: ReviewState): string {
      if (status === ReviewState.ACTIVE) {
        return 'primary-secondary';
      } else if (
        status === ReviewState.INACTIVE ||
        status === ReviewState.TEMP_BLOCKED
      ) {
        return 'success';
      } else {
        return 'danger';
      }
    },
    convertReviewStatusText(status: ReviewState): string {
      switch (status) {
        case ReviewState.ACTIVE:
          return '활성';
        case ReviewState.INACTIVE:
          return '비활성';
        case ReviewState.DELETED:
          return '삭제됨';
        case ReviewState.TEMP_BLOCKED:
          return '신고누적으로 인한 임시차단';
        case ReviewState.BLOCKED:
          return '차단';
      }
    },
    openOriginImage(uri: string): void {
      window.open(uri);
    },
    openReportListModal(): void {
      this.$emit('openReportListModal', this.productReview);
    },
    openReportModal(): void {
      this.$emit('openReportModal', this.productReview);
    },
    openDeleteModal(): void {
      this.$emit('openDeleteModal', this.productReview);
    },
  },
});
