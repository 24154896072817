import { PageInfo } from '@frientrip/domain';
import { ProductReviewCommentConnection } from './productReviewComment';
import { ListingProductV4 } from '@/domain/collection/model/listingProduct';

export interface User {
  id: number | null;
  nickname: string | null;
}

export enum ReviewState {
  ACTIVE = 'ACTIVE', // 보임
  INACTIVE = 'INACTIVE', // 숨김
  DELETED = 'DELETED', //삭제됨
  TEMP_BLOCKED = 'TEMP_BLOCKED', // 신고누적시의 임시차단
  BLOCKED = 'BLOCKED', // 신고처리완료(차단)
}

export interface TargetOfProductReview {
  id: number;
  product?: ListingProductV4 | null;
  orderItem?: OrderItem | null;
}

interface OrderItem {
  info?: {
    name: string;
    product: Product;
    host: Host;
    options: Option[];
  };
  schedule?: Schedule;
}

interface Product {
  id: string;
  title: string;
}

export interface Host {
  id: string;
  name: string;
}

interface Option {
  id: string;
  level: number;
  name: string;
}

interface Schedule {
  term: {
    dutation: number;
    startedAt: Date;
  };
}

enum ContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  HTML = 'HTML',
  TEXT = 'TEXT',
}

interface Content {
  id: string;
  type: ContentType;
  width: number;
  height: number;
  uri: string;
  contentId: string;
}

export interface ProductReviewsRequestParam {
  page?: number;
  size?: number;
  filter?: ProductReviewFilter;
}

export interface ProductReviewFilter {
  statusIn: ReviewState[] | null;
  ratingsIn: number[] | null;
  contentLike?: string | null;
  hostId?: number | null;
  productId?: number | null;
  writerId?: number | null;
}

export interface ProductReviewAPIResponse {
  id: string;
  content: string;
  rating: number;
  status: ReviewState;
  writer: User;
  createdAt: Date;
  updatedAt: Date;
  target: TargetOfProductReview | null;
  thumbsUpCount: number;
  commentCount: number;
  reportCount: number;
  attachmentCount: number;
  attachmentContents: Content[];
  latestComments: ProductReviewCommentConnection;
}

export interface ProductReviewConnection {
  edges: ProductReviewEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface ProductReviewEdge {
  node: ProductReviewAPIResponse;
  cursor: string;
}
