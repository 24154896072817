




































































































































































































































































































import Vue from 'vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { Option, Select, Table, TableColumn } from 'element-ui';
import {
  GET_PRODUCT_REVIEW_REPORT_TYPES_QUERY,
  GET_PRODUCT_REVIEWS_QUERY,
} from '../queries/query';
import Instant from '@/components/Labels/Instant.vue';
import {
  ProductReviewAPIResponse,
  ProductReviewConnection,
  ProductReviewEdge,
  ProductReviewFilter,
  ProductReviewsRequestParam,
  ReviewState,
} from '../model/productReview';
import ProductReviewListFilter from '../components/ProductReviewListFilter.vue';
import ProductReviewCard from '../components/ProductReviewCard.vue';
import ProductReviewCommentCard from '../components/ProductReviewCommentCard.vue';
import { ProductReviewService } from '../service/ProductReviewService';
import { apolloClient } from '@/apolloClient';
import {
  ProductReviewCommentAPIResponse,
  ProductReviewCommentModifyingParam,
  ProductReviewCommentsRequestParam,
  ProductReviewCommentWritingParam,
} from '../model/productReviewComment';
import {
  ProductReviewReportAPIResponse,
  ProductReviewReportingParam,
  ProductReviewReportingType,
  ProductReviewReportsRequestParam,
  ProductReviewReportState,
} from '../model/productReviewReport';
import { isHostAdmin } from '@/env';
import filters from '@/util/filters';

const productReviewService = new ProductReviewService(apolloClient);

export default Vue.extend({
  name: 'ProductReviewList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Instant,
    ProductReviewCard,
    ProductReviewCommentCard,
    ProductReviewListFilter,
  },
  data() {
    return {
      ReviewState,
      productReview: {
        reviewsForHostAndAdmin: { edges: [] as ProductReviewEdge[] },
      },
      reportTypes: [] as ProductReviewReportingType[],
      productReviewComments: [] as ProductReviewCommentAPIResponse[],
      productReviewReports: [] as ProductReviewReportAPIResponse[],
      isHostAdmin: isHostAdmin(),
      title: '상품 후기 목록',
      page: this.$route.query?.page || 1,
      size: this.$route.query?.pageSize || 10,
      totalRows: 0,
      disabled: false,
      reportModal: false,
      reportModalData: {
        id: null as number | null,
        content: '' as string,
        typeId: null as string | null,
        reason: '' as string,
      },
      reportListModal: false,
      reportListModalData: {
        id: '0',
        content: '',
        status: ReviewState.ACTIVE,
      },
      deleteCommentConfirmModal: false,
      deleteCommentConfirmModalData: {
        id: '0',
        content: '',
        confirmText: '',
      },
      deleteConfirmModal: false,
      deleteConfirmModalData: {
        id: '0',
        content: '',
        rating: 5.0,
        confirmText: '',
      },
      searchFilter: {
        statusIn: [ReviewState.ACTIVE, ReviewState.TEMP_BLOCKED],
        ratingsIn: [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
        contentLike: this.$route.query?.contentLike?.toString() || null,
        hostId: Number(this.$route.query?.hostId) || null,
        productId: Number(this.$route.query?.productId) || null,
        writerId: Number(this.$route.query?.writerId) || null,
      } as ProductReviewFilter,
      filter: {
        statusIn: [ReviewState.ACTIVE, ReviewState.TEMP_BLOCKED],
        ratingsIn: [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
      } as ProductReviewFilter,
      showErrorMessageTime: 0,
      errorMessage: '',
      errorMessageType: 'frip-warning',
      formatNumber: filters.formatNumber,
      isSearchDetailOpen: false,
    };
  },
  computed: {
    productReviews(): ProductReviewAPIResponse[] {
      if (this.productReview.reviewsForHostAndAdmin.edges) {
        return this.productReview.reviewsForHostAndAdmin.edges.map(
          edge => edge.node
        );
      } else {
        return [];
      }
    },
    searchDetailToggleIcon(): string {
      if (!this.isSearchDetailOpen) {
        return 'iconDownArrow';
      } else {
        return 'iconTopArrow';
      }
    },
  },
  activated: async function (): Promise<void> {
    this.page = this.$route.query.page || 1;
    this.size = this.$route.query.pageSize || 10;
    this.searchFilter = {
      statusIn: [ReviewState.ACTIVE, ReviewState.TEMP_BLOCKED],
      ratingsIn: [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
      contentLike: this.$route.query?.contentLike?.toString() || undefined,
      hostId: Number(this.$route.query?.hostId) || undefined,
      productId: Number(this.$route.query?.productId) || undefined,
      writerId: Number(this.$route.query?.writerId) || undefined,
    };
    await this.getList();
  },
  methods: {
    pageClick(bvEvent: never, page: number): void {
      if (
        this.$route.path !==
        `/product-review/list?page=${page}&pageSize=${this.size}`
      ) {
        this.$router.push(
          `/product-review/list?page=${page}&pageSize=${this.size}`
        );
      }
    },
    convertReviewStatus(status: ReviewState): string {
      switch (status) {
        case ReviewState.ACTIVE:
          return '활성';
        case ReviewState.INACTIVE:
          return '비활성';
        case ReviewState.DELETED:
          return '삭제됨';
        case ReviewState.TEMP_BLOCKED:
          return '신고누적으로 인한 임시차단';
        case ReviewState.BLOCKED:
          return '차단';
      }
    },
    convertReviewReportStatus(status: ProductReviewReportState): string {
      switch (status) {
        case ProductReviewReportState.REPORTED:
          return '신고됨';
        case ProductReviewReportState.REJECTED:
          return '신고 처리됨 (';
        case ProductReviewReportState.DONE:
          return '신고 처리됨';
      }
    },
    initSearchFilter(): void {
      this.searchFilter = {
        statusIn: [ReviewState.ACTIVE, ReviewState.TEMP_BLOCKED],
        ratingsIn: [0.5, 1.0, 1.5, 2.0, 2.5, 3.0, 3.5, 4.0, 4.5, 5.0],
      };
    },
    async resetSearchFilter(): Promise<void> {
      this.page = 1;
      this.size = 10;
      this.initSearchFilter();
      await this.getList();
    },
    async getList(ratingsIn?: number[] | null): Promise<void> {
      try {
        this.disabled = true;
        this.filter = {
          statusIn: this.searchFilter.statusIn,
          contentLike: this.searchFilter.contentLike?.trim() || null,
          productId: this.searchFilter.productId || null,
          hostId: this.searchFilter.hostId || null,
          writerId: this.searchFilter.writerId || null,
          ratingsIn: ratingsIn || this.searchFilter.ratingsIn,
        };

        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `후기목록을 불러오는데 실패하였습니다. (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    openDeleteModal(row: ProductReviewAPIResponse): void {
      this.deleteConfirmModal = true;
      this.deleteConfirmModalData.id = row.id;
      this.deleteConfirmModalData.content = row.content;
      this.deleteConfirmModalData.rating = row.rating;
      this.deleteConfirmModalData.confirmText = '';
    },
    async deleteProductReview(id: string): Promise<void> {
      this.deleteConfirmModal = false;

      if (this.deleteConfirmModalData.confirmText !== '후기삭제') {
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: '후기삭제 확인메시지가 일치하지 않습니다.',
          position: 'bottom-right',
        });
        return;
      }

      try {
        this.disabled = true;
        await productReviewService.deleteProductReview(id);
        this.$notify({
          title: '요청 성공',
          type: 'success',
          message: `후기가 삭제되었습니다.`,
          position: 'bottom-right',
        });
        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `후기삭제 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async findProductReviewComments(id: string, after?: string): Promise<void> {
      const param: ProductReviewCommentsRequestParam = {
        first: 10,
        after,
        productReviewId: id,
      };
      const productReviewCommentConnection =
        await productReviewService.findProductReviewComments(param);

      this.productReviewComments = productReviewCommentConnection.edges.map(
        edge => edge.node
      );
    },
    openReportModal(row: ProductReviewAPIResponse): void {
      this.reportModalData.id = parseInt(row.id);
      this.reportModalData.content = row.content;
      this.reportModalData.typeId = null;
      this.reportModalData.reason = '';
      this.reportModal = true;
    },
    async openReportListModal(row: ProductReviewAPIResponse): Promise<void> {
      this.productReviewReports = [];
      this.reportListModalData.id = row.id;
      this.reportListModalData.content = row.content;
      this.reportListModalData.status = row.status;
      await this.findProductReviewReports(row.id);
      this.reportListModal = true;
    },
    async findProductReviewReports(
      id: string,
      page?: number,
      size?: number
    ): Promise<void> {
      const param: ProductReviewReportsRequestParam = {
        page,
        size,
        filter: { productReviewId: parseInt(id) },
      };
      const productReviewReportConnection =
        await productReviewService.findProductReviewReports(param);

      this.productReviewReports = productReviewReportConnection.edges.map(
        edge => edge.node
      );
    },
    async rollbackTempBlockedReview(
      id: string,
      content: string
    ): Promise<void> {
      if (
        window.confirm(
          `임시차단된 후기를 차단 해제 할까요?\n\n후기내용: '${content}'\n\n`
        )
      ) {
        try {
          this.disabled = true;
          await productReviewService.rollbackTempBlockedProductReview(id);
          this.$notify({
            title: '요청 성공',
            type: 'success',
            message: `임시차단이 해제되었습니다.`,
            position: 'bottom-right',
          });
          await this.$apollo.queries.productReview.refetch();
        } catch (error) {
          let errorMessage = `알수없는 오류`;
          if (error instanceof Error) {
            errorMessage = error.message;
          }
          this.$notify({
            title: '요청 실패',
            type: 'warning',
            message: `임시차단 실패 (${errorMessage})`,
            position: 'bottom-right',
          });
        } finally {
          this.disabled = false;
          this.reportListModal = false;
        }
      }
    },
    async blockReview(id: string, content: string): Promise<void> {
      const promptText = window.prompt(
        `이 후기를 진짜 차단할까요?\n\n후기내용: '${content}'\n\n차단하려면 '후기차단'를 입력해주세요.`,
        ''
      );
      if (promptText === '후기차단') {
        try {
          this.disabled = true;
          await productReviewService.blockProductReview(id);
          this.$notify({
            title: '요청 성공',
            type: 'success',
            message: '후기가 차단되었습니다.',
            position: 'bottom-right',
          });
          await this.$apollo.queries.productReview.refetch();
        } catch (error) {
          let errorMessage = `알수없는 오류`;
          if (error instanceof Error) {
            errorMessage = error.message;
          }
          this.$notify({
            title: '요청 실패',
            type: 'warning',
            message: `후기차단 실패 (${errorMessage})`,
            position: 'bottom-right',
          });
        } finally {
          this.disabled = false;
          this.reportListModal = false;
        }
      }
    },
    async restoreReview(id: string): Promise<void> {
      try {
        this.disabled = true;
        await productReviewService.restoreProductReview(id);
        this.$notify({
          title: '요청 성공',
          type: 'success',
          message: '후기 차단이 해제되었습니다. .',
          position: 'bottom-right',
        });
        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `후기 차단해제 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
        this.reportListModal = false;
      }
    },
    openCommentDeleteModal(row: ProductReviewCommentAPIResponse): void {
      this.deleteCommentConfirmModal = true;
      this.deleteCommentConfirmModalData.id = row.id;
      this.deleteCommentConfirmModalData.content = row.content;
      this.deleteCommentConfirmModalData.confirmText = '';
    },
    async deleteProductReviewComment(id: string): Promise<void> {
      this.deleteCommentConfirmModal = false;

      if (this.deleteCommentConfirmModalData.confirmText !== '댓글삭제') {
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `댓글삭제 확인메시지가 일치하지 않습니다.`,
          position: 'bottom-right',
        });
        return;
      }

      try {
        this.disabled = true;
        await productReviewService.deleteProductReviewComment(id);
        this.$notify({
          title: '요청 성공',
          type: 'success',
          message: '댓글이 삭제되었습니다.',
          position: 'bottom-right',
        });
        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `댓글 삭제 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async writeProductReviewComment(
      param: ProductReviewCommentWritingParam
    ): Promise<void> {
      try {
        this.disabled = true;
        await productReviewService.writeProductReviewComment(param);
        this.$notify({
          title: '요청 성공',
          type: 'success',
          message: '댓글이 작성되었습니다.',
          position: 'bottom-right',
        });
        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `댓글 작성 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async modifyProductReviewComment(
      param: ProductReviewCommentModifyingParam
    ): Promise<void> {
      try {
        this.disabled = true;
        await productReviewService.modifyProductReviewComment(param);
        this.$notify({
          title: '요청 성공',
          type: 'success',
          message: '댓글이 수정되었습니다.',
          position: 'bottom-right',
        });
        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `댓글 수정 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
    async reportProductReview(): Promise<void> {
      try {
        this.disabled = true;
        if (!this.reportModalData.id) {
          throw new Error('후기정보 없음');
        }
        if (!this.reportModalData.typeId) {
          throw new Error('신고사유 없음');
        }
        const param: ProductReviewReportingParam = {
          productReviewId: this.reportModalData.id,
          typeId: parseInt(this.reportModalData.typeId),
          reason: this.reportModalData.reason?.trim() || undefined,
        };
        await productReviewService.reportProductReview(param);
        this.$notify({
          title: '요청 성공',
          type: 'success',
          message: '후기 신고가 제출되었습니다.',
          position: 'bottom-right',
        });
        this.reportModal = false;
        await this.$apollo.queries.productReview.refetch();
      } catch (error) {
        let errorMessage = `알수없는 오류`;
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        this.$notify({
          title: '요청 실패',
          type: 'warning',
          message: `후기 신고 실패 (${errorMessage})`,
          position: 'bottom-right',
        });
      } finally {
        this.disabled = false;
      }
    },
  },
  apollo: {
    productReview: {
      query: GET_PRODUCT_REVIEWS_QUERY,
      variables(): ProductReviewsRequestParam {
        return {
          page: Number(this.page),
          size: Number(this.size),
          filter: this.filter,
        };
      },
      error(e: ApolloError): void {
        this.disabled = false;
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          productReview: { reviewsForHostAndAdmin: ProductReviewConnection };
        }>
      ): void {
        this.disabled = false;
        this.totalRows =
          result.data.productReview.reviewsForHostAndAdmin.totalCount;
      },
      update: data => data.productReview,
    },
    reportTypes: {
      query: GET_PRODUCT_REVIEW_REPORT_TYPES_QUERY,
      error(e: ApolloError): void {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{
          productReview: { reportTypes: ProductReviewReportingType[] };
        }>
      ): void {
        this.reportTypes = result.data.productReview.reportTypes;
      },
      update: data => data.productReview.reportTypes,
    },
  },
});
