import gql from 'graphql-tag';

export const DELETE_REVIEW_MUTATION = gql`
  mutation DeleteProductReview($id: ID!) {
    deleteProductReview(id: $id) {
      success
      message
    }
  }
`;

export const BLOCK_PRODUCT_REVIEW_MUTATION = gql`
  mutation BlockProductReview($id: ID!) {
    blockProductReview(id: $id) {
      id
      status
      createdAt
    }
  }
`;

export const RESTORE_PRODUCT_REVIEW_MUTATION = gql`
  mutation RestoreProductReview($id: ID!) {
    restoreProductReview(id: $id) {
      id
      status
      createdAt
    }
  }
`;

export const DELETE_COMMENT_MUTATION = gql`
  mutation DeleteProductReviewComment($id: ID!) {
    deleteProductReviewComment(id: $id) {
      success
      message
    }
  }
`;

export const WRITE_COMMENT_MUTATION = gql`
  mutation WriteComment($param: ProductReviewCommentWritingParam) {
    writeProductReviewComment(param: $param) {
      id
      content
    }
  }
`;

export const MODIFY_COMMENT_MUTATION = gql`
  mutation ModifyComment($param: ProductReviewCommentModifyingParam) {
    modifyProductReviewComment(param: $param) {
      id
      content
    }
  }
`;

export const REPORT_PRODUCT_REVIEW_MUTATION = gql`
  mutation ReportProductReview($param: ProductReviewReportingParam) {
    reportProductReview(param: $param) {
      id
      reason
      status
      createdAt
    }
  }
`;
