import { PageInfo } from '@frientrip/domain';
import { ReviewState, TargetOfProductReview, User } from './productReview';

export interface ProductReviewReportsRequestParam {
  page?: number;
  size?: number;
  filter?: ProductReviewReportingFilter;
}

export enum ProductReviewReportState {
  REPORTED = 'REPORTED', //  "신고됨(처리 전)"
  REJECTED = 'REJECTED', //  "신고 반려됨(처리 완료)"
  DONE = 'DONE', //  "삭제됨(처리 완료)"
}

export interface ProductReviewReportingFilter {
  typeIds?: string[];
  statusIn?: ProductReviewReportState[] | null;
  reasonLike?: string | null;
  reporterId?: number | null;
  productReviewId?: number | null;
}

export interface ProductReviewReportingType {
  id: string;
  name: string;
  description?: string | null;
}

interface ProductReview {
  id: string;
  content: string;
  rating: number;
  status: ReviewState;
  reportCount: number;
  reported: boolean;
  target: TargetOfProductReview;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProductReviewReportAPIResponse {
  id: string;
  reporter: User;
  productReview?: ProductReview | null;
  type: ProductReviewReportingType;
  reason?: string | null;
  status: ProductReviewReportState;
  createdAt: Date;
  updatedAt: Date;
}

export interface ProductReviewReportConnection {
  edges: ProductReviewReportEdge[];
  totalCount: number;
  pageInfo: PageInfo;
}

export interface ProductReviewReportEdge {
  node: ProductReviewReportAPIResponse;
  cursor: string;
}

export interface ProductReviewReportingParam {
  productReviewId: number;
  typeId: number;
  reason?: string;
}
