























































































import Vue from 'vue';
import { ReviewState } from '../model/productReview';

export default Vue.extend({
  name: 'ProductReviewListFilter',
  components: {},
  props: ['isHostAdmin', 'disabled', 'searchFilter'],
  data() {
    return {
      statusOptions: [
        { value: ReviewState.ACTIVE, text: '활성' },
        { value: ReviewState.INACTIVE, text: '비활성' },
        { value: ReviewState.DELETED, text: '삭제됨' },
        { value: ReviewState.TEMP_BLOCKED, text: '신고누적으로 인한 임시차단' },
        { value: ReviewState.BLOCKED, text: '차단' },
      ],
      ratingsIn: [0, 1, 2, 3, 4, 5],
      ratingOptions: [
        { value: 0, text: '0.1 ~ 1' },
        { value: 1, text: '1 ~ 2' },
        { value: 2, text: '2 ~ 3' },
        { value: 3, text: '3 ~ 4' },
        { value: 4, text: '4 ~ 5' },
        { value: 5, text: '5' },
      ],
    };
  },
  methods: {
    search(): void {
      const newRatingsIn: number[] = [];
      this.ratingsIn.forEach(rating => {
        switch (rating) {
          case 0:
            newRatingsIn.push(0.5, 1);
            break;
          case 1:
            newRatingsIn.push(1.5, 2);
            break;
          case 2:
            newRatingsIn.push(2.5, 3);
            break;
          case 3:
            newRatingsIn.push(3.5, 4);
            break;
          case 4:
            newRatingsIn.push(4.5, 5);
            break;
          case 5:
            newRatingsIn.push(5);
            break;
        }
      });
      this.$emit('getList', newRatingsIn.length ? newRatingsIn : null);
    },
  },
});
